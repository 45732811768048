import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  FullName,
  Email,
  Phone,
} from "../../../formGroups/PersonalDetailsGroup";

import { CompactContext } from "../../../App";

export default function YourDetails({ control, register }) {
  //eslint-disable-next-line
  const compact = useContext(CompactContext);
//console.log(compact)
  return (
    <>
      <FullName control={control} required={true} register={register} />
      <Email control={control} required={true} register={register} />
      <Phone control={control} required={true} register={register} />
    </>
  );
}

// PropTypes
YourDetails.propTypes = {
  control: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired,
};
